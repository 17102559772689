import "./_style.scss"

import React, {useState} from "react";
import {useUpdateCarMutation} from "../../../redux/apiSlice/carApiSlice";
import {createErrorMessage} from "../../../components/AlertContainer/alert.utils";


const CustomerField = (props) => {
    //If you are editing, show input fields for all fields (even if they are empty)
    if (props.editMode) {
        return (
            <div className="field-container" key={props.key}>
                <span className="field-title">
                    {props.fieldTitle}
                </span>
                <div className="field-content">
                    {props.children}
                </div>
            </div>
        )
    }
    //If you are not editing, and don't have a value for the field, don't show it
    if (!props.currentValue) {
        return null
    }
    //If you are not editing, and have a value for the field, show it
    return (
        <div className="field-container" key={props.key}>
            <span className="field-title">
                {props.fieldTitle}
            </span>
            <div className="field-content input-field-wrapper">
                {props.currentValue}
            </div>
        </div>
    )
}

const CustomerFieldsCard = (props) => {

    const [isEditing, setIsEditing] = useState(false);
    const [fieldData, setFieldData] = useState({})
    const [isInputDisabled, setInputFieldDisabled] = useState(false)
    const [updateClientFieldsData, {isLoading, isError, error}] = useUpdateCarMutation()

    const carData = {
        baseData: {
            carId: props?.data?.additionalCarInformation?.baseData?.carId,
            vin: props?.data?.additionalCarInformation?.baseData?.vin,
            status: props.data?.status,
            type: props?.data?.additionalCarInformation?.baseData?.type,
            brand: props?.data?.additionalCarInformation?.baseData?.brand,
        },
        additionalCarInformation: {
            baseData: {
                carId: props?.data?.additionalCarInformation?.baseData?.carId,
                vin: props?.data?.additionalCarInformation?.baseData?.vin,
                status: props.data?.status,
                type: props?.data?.additionalCarInformation?.baseData?.type,
                brand: props?.data?.additionalCarInformation?.baseData?.brand,
            },
            licensePlates: [...props.data.additionalCarInformation.licensePlates]
        },
        fleetInformation: {
            customerId: props?.data?.contractInformation?.contractOrganisation?.id
        }
    };

    const handleChange = async (e) => {
        const {name, value} = e.target;
        setFieldData({...fieldData, [name]: value})
    }

    /**
     * Make API call here + do something so noFieldsMessage is not displayed after save
     */
    const handleCancelEdit = () => {
        setIsEditing(false)
        initializeFieldDataState()
    }
    const handleSave = async () => {
        setInputFieldDisabled(true)
        // first convert the data format of customer fields back to the one api gives us
        let customerFieldsReformatted = []
        Object.keys(fieldData).forEach(key => {
            // only need reformatting if there is value
            if (fieldData[key]) {
                customerFieldsReformatted.push(
                    {
                        "fieldName": key,
                        "value": fieldData[key]
                    }
                )
            }
        })
        updateClientFieldsData(
            {
                ...carData,
                additionalCarInformation: {
                    ...carData.additionalCarInformation,
                    customerFields: customerFieldsReformatted
                }
            }
        ).then(res => {
            if (!res.data) {
                initializeFieldDataState()
            }
            setInputFieldDisabled(false)
            setIsEditing(false)
        })
    }

    const FormControlButtons = () => {
        if (!props.allowEdit) {
            return <></>
        }
        return (
            <span className="button-group" hidden={false}>
                    {isLoading ?
                        (<button className="btn btn-primary btn-disabled">
                                <div className="dot-falling-container">
                                    <div className="dot-falling"/>
                                </div>
                            </button>
                        ) :
                        (
                            <>
                                {isEditing ?
                                    <>
                                        <button className="btn btn-light" onClick={() => handleCancelEdit()
                                        }>Cancel
                                        </button>
                                        <button className="btn btn-light" onClick={() => handleSave()}>Save</button>
                                    </>
                                    :
                                    <button className="btn btn-light" onClick={() => setIsEditing(true)}>Edit</button>
                                }
                            </>
                        )

                    }
                </span>
        )

    }
    let customerFields;

    const initializeFieldDataState = () => {
        let temp = {}
        for (let i = 0; i < props.uniqueFields.length; i++) {
            temp[props.uniqueFields[i]] = props.data.additionalCarInformation?.customerFields[props.uniqueFields[i]];
        }
        setFieldData(temp)
    }
    if (props.uniqueFields.length > 0 && props.uniqueFieldDescriptions.length > 0) {
        // FieldData is initially empty. The condition below make sure that the fieldData is populated only once
        if (Object.keys(fieldData).length === 0) {
            initializeFieldDataState()
        }

        customerFields =
            <div className="fields-container">
                {Object.keys(fieldData).sort(
                    (a, b) => {
                        // reduce order for reserve fields
                        if (b.toLowerCase().includes("reserve")) {
                            // z should be behind any letter
                            b = "z" + b
                        }
                        if (a.toLowerCase().includes("reserve")) {
                            a = "z" + a
                        }
                        return a.localeCompare(b)
                    }).map((field, i) => {
                        let fieldTitle = props.labels.carsPage?.carInfo[field]
                        return (
                            <CustomerField editMode={isEditing} currentValue={fieldData[field]} fieldTitle={fieldTitle}
                                           key={i}>
                                <input disabled={isInputDisabled} className="input-field-primary" type="text"
                                       name={field}
                                       value={fieldData[field]}
                                       onChange={e => handleChange(e)}
                                       style={{fontSize: "18px", width: "100%"}}
                                />
                            </CustomerField>
                        )
                    }
                )}
            </div>
    }


    const noFieldsMessage = (
        <div className="no-fields-container">
            <span>{props.labels.errorMessage.noCustomerFields}</span>
            <button className="btn btn-light" onClick={() => {
                setIsEditing(true)
            }}>Add Custom Field
            </button>
        </div>
    )
    const cannotSaveError = createErrorMessage(error, "", "h2", props.labels, "canNotLoad")

    return (
        <div className="customer-fields-content"
             hidden={Object.keys(props.data.additionalCarInformation?.customerFields).length === 0 && props.allowEdit===false}
        >
            <div className="blank-card">
                {/*show error and prevent user further try to change kunden field*/}
                {isError ? <>
                    {cannotSaveError}
                </> : <></>}

                {Object.keys(props.data.additionalCarInformation?.customerFields).length > 0 ? (
                    <div className="card-content">
                        <span className="header">
                            {props.labels?.carsPage?.titles?.customerFields}
                            <FormControlButtons/>
                        </span>
                        <div className="bottom-part">
                            {customerFields}
                        </div>
                    </div>
                ) : (
                    <div className="card-content">
                        <span className="header">
                            {props.labels?.carsPage?.titles?.customerFields}
                            {isEditing ? <FormControlButtons/> : null}
                        </span>
                        {/*If user is not editing, show "no fields message", if they are editing show all fields */}
                        {isEditing ? <div className="bottom-part">{customerFields}</div>
                            : noFieldsMessage}
                    </div>

                )}
            </div>
        </div>
    )
}

export default CustomerFieldsCard;