// import styling
import "./_style.scss"

//import helper
import {formatDateIfNotEmpty} from "../../../actions/date.actions";

//import internal
import GridHandler from "../../../components/GridHandler";
import LicensePlateModal from "./LicensePlateModal";
import AlertContainer from "../../../components/AlertContainer";
import {handleErrorMessage} from "../../../actions/handleErrorMessage";

//import slices
import {useUpdateCarMutation} from "../../../redux/apiSlice/carApiSlice";

//import external
import React, {useEffect} from "react";
import Moment from "moment";


const PlateNumberOverview = (props) => {
    const [updateLicensePlate, {
        isError,
        isLoading,
        error,
        isSuccess,
        isUninitialized,
        reset: resetQuery
    }] = useUpdateCarMutation()

    let licensePlateFromProps = props.data?.additionalCarInformation?.licensePlates
    //License plates that are displayed in grid and updated automatically
    const [licensePlates, setLicensePlates] = React.useState([]);


    //Most recently changed license plate, used to find idx in licensePlates array (necessary because order might change)
    const [newestValue, setNewestValue] = React.useState(null);

    if (licensePlateFromProps && !licensePlates.length && props.data?.additionalCarInformation?.licensePlates?.length) {
        //Initialize licensePlates array with data from api
        setLicensePlates(licensePlateFromProps)
    }

    const [customError, setCustomError] = React.useState(null);
    
    //Static Data about the car that is being edited (used for sending api call)
    const [carData] = React.useState({
        baseData: {
            carId: props?.data?.additionalCarInformation?.baseData?.carId,
            vin: props?.data?.additionalCarInformation?.baseData?.vin,
            status: props?.data?.status,
            type: props?.data?.additionalCarInformation?.baseData?.type,
            brand: props?.data?.additionalCarInformation?.baseData?.brand,
        },
        additionalCarInformation: {
            baseData: {
                carId: props?.data?.additionalCarInformation?.baseData?.carId,
                vin: props?.data?.additionalCarInformation?.baseData?.vin,
                status: props.data?.status,
                type: props?.data?.additionalCarInformation?.baseData?.type,
                brand: props?.data?.additionalCarInformation?.baseData?.brand,
            },
            licensePlates: [...props?.data?.additionalCarInformation?.licensePlates]
        },
        fleetInformation: {
            customerId: props?.data?.contractInformation?.contractOrganisation?.id
        }
    });
    const findLicensePlate = (plateNumber, fromDate) => {
        //If you don't provide a plateNumber, search using only fromDate
        if (!plateNumber) {
            return props.data?.additionalCarInformation?.licensePlates.findIndex(licensePlate => licensePlate.fromDate === fromDate)
        }
        return licensePlates.findIndex(licensePlate => licensePlate.licencePlate === plateNumber && licensePlate.fromDate === fromDate)
    }

    const columnDefs = [
        {
            headerName: props.labels?.carsPage?.carInfo?.plateNumber,
            colId: "plateNumber",
            valueGetter: function (params) {
                return params.data.licencePlate;
            },
            width: 200,
            editable: props.allowEdit,
            field: 'licencePlate',
            valueSetter: function (params) {
                const index = findLicensePlate(params.oldValue, params.data.fromDate);
                setNewestValue(params.newValue);
                if (params.newValue !== params.oldValue && index !== -1 && params.newValue !== "") {
                    let newLicensePlates = [...licensePlates];
                    newLicensePlates[index] = {
                        ...newLicensePlates[index],
                        licencePlate: params.newValue
                    }
                    setLicensePlates(newLicensePlates);
                    return true;
                }
                return false;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.startDate,
            colId: "startDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.fromDate);
            },
            // for sorting
            comparator: props.gridSortingFunc,
            sort: 'desc',
            // for filtering
            filter: 'agDateColumnFilter',
            filterParams: {
                // provide comparator function
                comparator: props.gridFilteringFunc
            },
            width: 180,
            field: 'fromDate',
            editable: false
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.endDate,
            colId: "endDate",
            valueGetter: function (params) {
                if (params.data.endDate) {
                    return formatDateIfNotEmpty(params.data.endDate);
                }
                return ("-")
            },
            // for sorting
            comparator: props.gridSortingFunc,
            // for filtering
            filter: 'agDateColumnFilter',
            filterParams: {
                // provide comparator function
                comparator: props.gridFilteringFunc
            },
            width: 180,
            field: 'endDate',
            editable: props.allowEdit,
            valueSetter: function (params) {
                if (params.newValue !== params.oldValue && params.newValue !== "") {
                    let newLicensePlates = [...licensePlates];
                    newLicensePlates[params.node.rowIndex] = {
                        ...newLicensePlates[params.node.rowIndex],
                        endDate: params.newValue
                    }
                    setLicensePlates(newLicensePlates);
                    return true;
                }
                return false;
            },
            cellEditor: 'agDateStringCellEditor'
        }
    ]


    const onCellValueChanged = (event) => {
        //If license plate hasn't changed this works (changing endDate)
        let index = findLicensePlate(event.data.licencePlate, event.data.fromDate);
        //If license plate has changed this works (changing license plate)
        if (index === -1) {
            index = findLicensePlate(newestValue, event.data.fromDate);
        }
        //If something goes wrong, find the license plate using fromDate only
        if (index === -1) {
            index = findLicensePlate(null, event.data.fromDate);
        }

        const previousPlateNumber = event.data.licencePlate;

        //If the license plate being modified is the current one, also send licence plate in baseData
        if (previousPlateNumber === props.data?.licencePlate || previousPlateNumber === props.data?.additionalCarInformation?.baseData?.licencePlate) {
            updateLicensePlate(
                {
                    baseData: {
                        ...carData.baseData,
                        licencePlate: licensePlates[index].licencePlate,
                    },
                    additionalCarInformation: {
                        baseData: {
                            ...carData.additionalCarInformation.baseData,
                            licencePlate: licensePlates[index].licencePlate,
                        },
                        licensePlates: [
                            {
                                ...licensePlates[index],
                                [event.colDef.field]: licensePlates[index][event.colDef.field],
                            },
                        ]
                    },
                    fleetInformation: {
                        ...carData.fleetInformation,
                    }
                }
            )
        } else {
            updateLicensePlate(
                {
                    baseData: {
                        ...carData.baseData,
                    },
                    additionalCarInformation: {
                        ...carData.additionalCarInformation,
                        licensePlates: [
                            {
                                ...licensePlates[index],
                                [event.colDef.field]: licensePlates[index][event.colDef.field],
                            },
                        ]
                    },
                    fleetInformation: {
                        ...carData.fleetInformation,
                    }
                }
            )
        }
    };


    useEffect(() => {
        //If request was successful, set license plate list
        if (isSuccess) {
            setLicensePlates(licensePlates)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isSuccess])


    const onAddLicensePlate = (newLicensePlateData) => {
        const newLicensePlate = {
            licencePlate: newLicensePlateData.licencePlate,
            fromDate: newLicensePlateData.fromDate,
            endDate: newLicensePlateData.endDate
        }

        //Check if fromDate is after endDate and show error + cancel if true
        if (Moment(newLicensePlate.fromDate).isAfter(Moment(newLicensePlate.endDate))) {
            setCustomError(props.labels?.warningMessage?.endDateBeforeStartDate);
            //Force error so data isn't updated + AlertContainer is shown
            updateLicensePlate({})
            return;
        }

        //Check if there is already a license plate with the same fromDate and show error + cancel if true
        if (!licensePlates.every((licensePlate) => licensePlate.fromDate !== newLicensePlate.fromDate)) {
            setCustomError(props.labels?.warningMessage?.duplicateStartDate);
            //Force error so data isn't updated + AlertContainer is shown
            updateLicensePlate({})
            return;
        }

        //For client side instant visual change
        setLicensePlates([...licensePlates, newLicensePlate]);

        //If the user checked that the new license plate is the current one
        const isCurrentChecked = newLicensePlateData.isCurrent;

        //If current is checked, update endDate for all other license plates where the conditions are fulfilled
        if (isCurrentChecked) {
            licensePlates.forEach((licensePlate) => {
                //For all older license plates that don't have an end date, set the end date to the new license plate's start date
                if (newLicensePlate.fromDate > licensePlate.fromDate && !licensePlate.endDate) {
                    // Update end date of affected license plates:


                    /* NOT NECESSARY BECAUSE WE ARE RELOADING THE WHOLE PAGE
                    const index = findLicensePlate(licensePlate.licencePlate, licensePlate.fromDate);
                    // Client side
                    let newLicensePlates = [...licensePlates];
                    newLicensePlates[index] = {
                        ...newLicensePlates[index],
                        endDate: newLicensePlate.fromDate
                    }
                    setLicensePlates(newLicensePlates);

                     */

                    // Server side (send api call)
                    updateLicensePlate(
                        {
                            baseData: {
                                ...carData.baseData,
                            },
                            additionalCarInformation: {
                                ...carData.additionalCarInformation,
                                licensePlates: [
                                    {
                                        ...licensePlate,
                                        endDate: newLicensePlate.fromDate
                                    }

                                ]
                            },
                            fleetInformation: {
                                customerId: props.data.contractInformation?.contractOrganisation?.id
                            }
                        }
                    )

                }
            })
            //Add new license plate and set as current
            updateLicensePlate(
                {
                    baseData: {
                        ...carData.baseData,
                        licencePlate: newLicensePlate.licencePlate
                    },
                    additionalCarInformation: {
                        baseData: {
                            ...carData.additionalCarInformation.baseData,
                            licencePlate: newLicensePlate.licencePlate
                        },
                        licensePlates: [
                            newLicensePlate
                        ]
                    },
                    fleetInformation: {
                        customerId: props.data.contractInformation?.contractOrganisation?.id
                    }
                }
            )
            //Reload page to keep changes => Yue: there is no need to reload the whole page anymore
            // after moving data out of grid option, the reload detection from ag grid works.
            // window.location.reload();
        } else {
            //Add new license plate (don't set as current)
            updateLicensePlate(
                {
                    baseData: {
                        ...carData.baseData,
                    },
                    additionalCarInformation: {
                        ...carData.additionalCarInformation,
                        licensePlates: [
                            newLicensePlate
                        ]
                    },
                    fleetInformation: {
                        customerId: props.data.contractInformation?.contractOrganisation?.id
                    }

                }
            )
        }
    }


    const HeaderContent = () => {
        return (
            <div className="header" onClick={() => {
                if (!isUninitialized) {
                    resetQuery();
                    setCustomError(null);
                }
            }}>
                {props.labels?.carsPage?.titles?.plateHistory}
                <LicensePlateModal
                    isLoading={isLoading}
                    labels={props.labels}
                    onSubmit={onAddLicensePlate}
                    hidden={!props.allowEdit}
                />
            </div>
        )
    }
    if (props.data?.additionalCarInformation?.licensePlates || props.allowEdit) {
        return (
            <div className="plate-number-grid-container">
                <AlertContainer alertStyle={"alert-warning"}
                                show={isError}>{handleErrorMessage(error, props?.labels, customError)}</AlertContainer>
                <GridHandler
                    gridHeight={"300px"}
                    data={props.data?.additionalCarInformation?.licensePlates}
                    columnDefs={columnDefs}
                    hidePagination={true}
                    hideSearch={true}
                    downloadable={false}
                    gridName={`upto_plates_${props.data?.carId}`}
                    headerContent={<HeaderContent/>}
                    onCellValueChanged={onCellValueChanged}
                />
            </div>
        )
    } else {
        return null;
    }

}

export default PlateNumberOverview;