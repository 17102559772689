/*
Purpose: render telematic data into a table, if available.
otherwise the section is not shown at all
*/
// import external
import React, {useState} from "react";
import Moment from "moment"

// import styling
import "./_style.scss"

// import internal
import ButtonWithIcon from "../ButtonWithIcon";
import {
    useDeleteTelematicMutation,
    useGetMileageQuery,
    useUpdateCarMutation
} from "../../redux/apiSlice/carApiSlice";
import AlertContainer from "../AlertContainer";
import FormModal from "../FormModal";
import FeatureIcon from "../FeatureIcon";
import DatePickerHandler from "../DatePickerHandler";
import WarningContainer from "../WarningContainer";

//import helper
import {thousandsSeparator} from "../../actions/formateNumbers.actions";
import {handleErrorMessage} from "../../actions/handleErrorMessage";

// import assets
import {ReactComponent as PlusIcon} from "../../assets/svg/icon-plus.svg";
import {ReactComponent as SpeedometerIcon} from "../../assets/svg/icon-speedometer.svg";
import {ReactComponent as GarbageIcon} from "../../assets/svg/icon-trash.svg";
import LoadingComponent from "../LoadingComponent";
import {CSSTransition, TransitionGroup} from "react-transition-group";


const AddMileageFormModal=(props)=>{
    // spread props
    const {sortedEntries, carData, setShowModalFunc}=props

    const [updateTelematicData] = useUpdateCarMutation();

    // What message / reason is shown
    const [warningMessage, setWarningMessage] = useState(null);

    // Whether the warning is fatal or not (if it is, the user can't continue)
    const [fatalWarning, setFatalWarning] = useState(false);


    // The default date for the date picker, is today's date
    const defaultDate = Moment().format("YYYY-MM-DDT00:00:00Z")

    // Stores data for input fields
    const [odometerData, setOdometerData] = useState({
        date: defaultDate,
        odometerValue: ""
    });


    // Handles the closing of the modal (reset data, close modal, reset warning)
    const onCloseModal = () => {
        setOdometerData({
            date: defaultDate,
            odometerValue: ""
        })
        resetWarning();
        setShowModalFunc(false);
    }

    // Resets everything related to the display of the warning message
    const resetWarning = () => {
        setWarningMessage(null);
        setFatalWarning(null);
        userContinue = null;
    }

    // Handles mileage value input field
    const handleChange = (e) => {
        resetWarning();
        setOdometerData({
            ...odometerData,
            [e.target.name]: e.target.value
        })
    }

    const handleMileageOnBlur=(e)=>{
        setOdometerData({
            ...odometerData,
            [e.target.name]: Number(e.target.value).toFixed(0)
        })
    }
    // Handles date input field
    const handleDateChange = (date) => {
        resetWarning();
        // If the date is null (only possible if user clicks "Reset" button, then don't try to format it)
        if(!date){
            setOdometerData({...odometerData, date: ""})
            return;
        }
        setOdometerData({...odometerData, date: Moment(date).format("YYYY-MM-DDT00:00:00Z")})
    }



    // Once user selects a response (continue or cancel) this is set to either false or true
    let userContinue = null;



    // Called when user adds a new entry
    const onAddEntry = () => {
        //Check if entered data is "valid" (safe and makes sense logically) and if user hasn't already confirmed to continue
        if(!dataIsValid() && !userContinue){
            return;
        }

        userContinue = null;
        // Sends API call to add new data
        updateTelematicData({
            ...carData,
            additionalCarInformation: {
                ...carData?.additionalCarInformation,
                telematicData: [
                    {
                        date: odometerData.date,
                        odometerValue: odometerData.odometerValue
                    }]
            }
        })

        onCloseModal();
    }


    // Checks if the data entered by the user is valid (true: valid, false: invalid)
    const dataIsValid = () => {
        const mileageValue = odometerData.odometerValue;
        // Checks if Number(mileageValue) is NaN, so if what user entered cannot be converted to a number
        // eslint-disable-next-line no-self-compare
        if(mileageValue !== mileageValue){
            setWarningMessage(`${props.labels?.warningMessage?.mileageNotANumber}`);
            setFatalWarning(true);
            return false;
        }

        // If mileage counter value is zero
        if(Number(mileageValue) === 0){
            setWarningMessage(`${props.labels?.warningMessage?.negativeMileage}`);
            return false;
        }

        // If mileage counter value is negative
        if(mileageValue < 0){
            setWarningMessage(`${props.labels?.warningMessage?.negativeMileage}`);
            setFatalWarning(true);
            return false;
        }

        // Here we start to compare with older entries, so if there is no other data these checks are no longer relevant
        if(sortedEntries.length === 0){
            return true;
        }

        let lastEntry = sortedEntries[0];
        // if mileage counter value is lower than last entry but date is more recent
        if(mileageValue <= lastEntry.odometerValue && Moment(odometerData.date).isAfter(lastEntry.date)){
            setWarningMessage(`${props.labels?.warningMessage?.newerDateButLowerMileage} `);
            return false;
        }

        else if(!Moment(odometerData.date).isAfter(Moment(lastEntry.date)) && mileageValue > lastEntry.odometerValue){
            setWarningMessage(`${props.labels?.warningMessage?.olderDateButHigherMileage}`);
            return false;

        }
        return true;
    }
    return (
        <FormModal
            // "show" prop is important because it toggles overflow between auto and hidden
            show={props.show}
                   onClose={onCloseModal}
                   onOutsideClick={() => setShowModalFunc(false)}
                   title={props.labels?.carsPage?.titles?.addMileageData}
                   featuredIcon={
                       <FeatureIcon>
                           <SpeedometerIcon style={{stroke:"#344054"}}/>
                       </FeatureIcon>
                   }>
            <div className="form-modal-content">
                <div className="form-modal-input-container">
                    <div className="form-modal-input-title">
                        {props.labels?.carsPage?.mileageData?.mileageCounter}
                        <span className="required-label">
                                ({props.labels?.otherElements?.required})
                            </span>
                    </div>
                    <div className="form-modal-input-container-field">
                        <input className="input-field-primary" type="number" name="odometerValue" value={odometerData.odometerValue} onChange={e => handleChange(e)} onBlur={e=>handleMileageOnBlur(e)} style={{width:"100%"}}/>
                    </div>
                </div>

                <div className="form-modal-input-container">
                    <div className="form-modal-input-title">
                        {props.labels?.carsPage?.mileageData?.date}
                        <span className="required-label">
                                ({props.labels?.otherElements?.required})
                            </span>
                    </div>
                    <div className="form-modal-input-container-field">
                        <DatePickerHandler startDate={new Date()} onChange={handleDateChange}/>
                    </div>
                </div>

                <WarningContainer
                    hidden={!warningMessage}
                    title={props.labels?.warningMessage?.attention}
                >
                    {warningMessage}
                </WarningContainer>


                {/* Modal Footer */}
                <div className="form-modal-footer">
                    <button
                        onClick={onCloseModal}
                        className="btn btn-light"
                        style={{width: "45%"}}
                    >
                        {props.labels?.otherElements?.cancel}
                    </button>

                    {warningMessage ?

                        <button
                            onClick={() => {
                                userContinue = true;
                                onAddEntry();
                            }}
                            disabled={odometerData?.date?.length === 0 || odometerData?.odometerValue?.length === 0 || fatalWarning}
                            className="btn btn-danger"
                            style={{width: "45%"}}
                        >
                            {fatalWarning ? props.labels?.otherElements?.unavailable : props.labels?.otherElements?.save}
                        </button>

                        :

                        <button
                            onClick={() => {
                                onAddEntry();
                            }}
                            disabled={odometerData?.date?.length === 0 || odometerData?.odometerValue?.length === 0}
                            className="btn btn-primary"
                            style={{width: "45%"}}
                        >
                            {props.labels?.otherElements?.save}
                        </button>
                    }
                </div>
            </div>
        </FormModal>
    )
}
// Used in the body of the POST request
export const formatCarData = (data)=>{
    return {
        baseData: {
            carId: data?.additionalCarInformation?.baseData?.carId,
            vin: data?.additionalCarInformation?.baseData?.vin,
            status: data?.status,
            type: data?.additionalCarInformation?.baseData?.type,
            brand: data?.additionalCarInformation?.baseData?.brand,
        },
        additionalCarInformation: {
            baseData: {
                carId: data?.additionalCarInformation?.baseData?.carId,
                vin: data?.additionalCarInformation?.baseData?.vin,
                status: data?.status,
                type: data?.additionalCarInformation?.baseData?.type,
                brand: data?.additionalCarInformation?.baseData?.brand,
            },
            licensePlates: [...data?.additionalCarInformation?.licensePlates]
        },
        fleetInformation: {
            customerId: data?.contractInformation?.contractOrganisation?.id
        }
    }
};

const CarTelematicDataTable = (props) => {
    // Whether the modal is shown or not
    const [showModal, setShowModal] = useState(false);

    const [deleteTelematic, {isLoading: isDeleting, isError: isDeleteError}] = useDeleteTelematicMutation();

    // Besides all the role required in the car details page, to change the tekematic data we need more

    const {
        data: mileageData,
        isLoading: isLoadingMileageData,
        isFetching: isFetchingMileageData,
        isError:isFetchingMileageError,
    } = useGetMileageQuery(props.data?.additionalCarInformation?.baseData?.vin);

    const [addMileageError, setAddMileageError] = useState(false)
    const [isAddMileageLoading, setIsAddMileageLoading] =useState(false)
    let content;

    let carData=formatCarData(props.data)

    //Necessary so that correct data is displayed when user switches tabs and comes back (and doesn't have to reload the page)
    // if(isSuccessVehicleData && !telematicData?.length && mileageData?.length){
    //     setTelematicData(mileageData)
    // }

    async function handleDeleteTelematic(id) {
        const vin = props?.data?.additionalCarInformation?.baseData?.vin;
        await deleteTelematic({
            vin: vin,
            id: id,
        });
    }
    let tableContent;

    const sortedEntries = tableContent = [].concat(mileageData)
        .sort((a, b) => a.date < b.date ? 1 : -1);

    if(mileageData?.length > 0){
        tableContent = [].concat(mileageData)

            // New Mileage API likes to send duplicates so we filter here
            .filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
            .sort((a, b) => a.date < b.date ? 1 : -1)
            .map((item, i) => {
                    const c_name = i === 0 ? " highlighted" : "";
                    return (
                        <CSSTransition  key={item.id}
                                        timeout={500}
                                        classNames="item">
                            <tr key={i}>
                                <td className={"mileage-data-column mileage-counter" + c_name}>{thousandsSeparator(item?.odometerValue)}</td>
                                <td className={"mileage-data-column date" + c_name}>
                                    {Moment(item?.date).format('DD.MM.YYYY')}

                                </td>

                                <td className={"mileage-data-column" + c_name} style={{width:"15px"}}>
                                    <GarbageIcon className={"delete-icon " + (isDeleting || isAddMileageLoading || isFetchingMileageData ? "disabled" : "")}
                                                 onClick={() => !(isDeleting || isAddMileageLoading || isFetchingMileageData) && handleDeleteTelematic(item?.id)}
                                    />
                                </td>
                            </tr>
                        </CSSTransition>
                    )
                }
            )

    } else {
        tableContent = (
            <tr key="no data">
                <td className="mileage-counter-column">-</td>
                <td className="date-column">-</td>
            </tr>
        )
    }
    if (isLoadingMileageData) {
        content= <LoadingComponent show={true}></LoadingComponent>;
    }else if(isFetchingMileageError){
        content=(<AlertContainer key="alertContainerNoData" show={true} alertStyle={"alert-warning"}>
            {handleErrorMessage(addMileageError, props?.labels)}
        </AlertContainer>)
    } else {
        content=(
            <>
                {/*Yue: we can not toggle between AddMileageFormModal and <></> based on show. the consequence is that autoflow on the right panel is never set back to auto */}
                    <AddMileageFormModal show={showModal} sortedEntries={sortedEntries} setShowModalFunc={setShowModal} setIsAddMileageLoadingFunc={setIsAddMileageLoading}
                                         setIsAddMileageErrorFunc={setAddMileageError} labels={props.labels} carData={carData}
                    ></AddMileageFormModal>

                <AlertContainer alertStyle={"alert-warning"} show={addMileageError || isDeleteError}>{
                    // if it was delete error, show delete specific error, otherwise generic
                    isDeleteError ? props.labels?.warningMessage?.deleteTelematicFailed : handleErrorMessage(addMileageError, props?.labels)}
                </AlertContainer>
                <div className="blank-card" style={{width:"575px"}}>
                    <div className="section-card">
                        <div className="button-container" hidden={!props.allowEdit} style={{whiteSpace:"nowrap", marginBottom:"5px"}}>
                            {isAddMileageLoading || isDeleting || isFetchingMileageData ?
                                // Yue: adding a min-width hear because otherwise when it is ... the width shrink so much it is
                                // weird
                                <button className="btn btn-primary btn-disabled" style={{minWidth:"180px", minHeight:"40px"}}>
                                    <div className="dot-falling-container">
                                        <div className="dot-falling"/>
                                    </div>
                                </button>
                                :
                                <ButtonWithIcon text={props.labels?.otherElements?.addEntry}
                                                buttonStyle="btn-primary min-width-180px"
                                                clickFunc={() => {setShowModal(true)}
                                }
                                >
                                    <PlusIcon className="inner-icon"></PlusIcon>
                                </ButtonWithIcon>
                            }
                        </div>

                        <span>
                        <table className="mileage-data-table">
                            <thead>
                                <tr>
                                    <th className="mileage-counter-column">{props.labels?.carsPage?.mileageData?.mileageCounter}</th>
                                    <th className="date-column">
                                        {props.labels?.carsPage?.mileageData?.date}
                                    </th>
                                </tr>
                            </thead>
                            <TransitionGroup component="tbody">
                                {tableContent}
                            </TransitionGroup>
                        </table>
                    </span>

                    </div>
                </div>
            </>
        )
    }
    return (content)
}
export default CarTelematicDataTable