// import external
import React from "react";

// import helper function
import { formatNumber, thousandsSeparator } from "../../actions/formateNumbers.actions";
import { formatDateIfNotEmpty } from "../../actions/date.actions";
// import internal slices
import GridHandler from "../GridHandler";

/**
 *
 * @param {*} props (not used)
 * loads data for the cost overview and defines what to show
 * @returns
 */
const InvoicePositionsOverview = (props) => {

    // each column definition results in one column.
    const columnDefs = [
        // {
        //     //Link Icon to subPage Invoicedetail Page
        //     cellRenderer: function (params) {
        //         return (
        //             <Link to={"/supplierInvoices/" + params.data.id}>
        //                 <OpenNew className="icon" alt="open row details icon"></OpenNew>
        //             </Link>
        //         );
        //     },
        //     pinned: 'left',
        //     resizable: false,
        //     sortable: false,
        //     filter: false,
        //     width:65,
        //     suppressMenu: true,
        //     colId:"icon",
        //     suppressMovable:true
        // },
        {
            headerName: props.labels.costPage.costInfo.invoiceNumber,
            colId: "invoiceNumber",
            valueGetter: function (params) {
                return params.data.invoiceNumber;
            },
        },
        {
            headerName: props.labels.costPage.costInfo.positionDate,
            colId: "positionDate",
            valueGetter: function (params) {
                return params.data.position.posDate?formatDateIfNotEmpty(params.data.position.posDate):props.labels.otherElements.unavailable;
            },
            filter: 'agDateColumnFilter',
            // sorting related
            sort: 'desc',
            comparator: props.gridSortingFunc,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: props.gridFilteringFunc
            }
        },
        {
            headerName: props.labels.costPage.costInfo.description,
            colId: "description",
            valueGetter: function (params) {
                return params.data.position.description;
            }
        },
        {
            headerName: props.labels.costPage.costInfo.entireLineText,
            colId: "entireLineText",
            valueGetter: function (params) {
                return params.data.position.entireLineText;
            }
        },
        {
            headerName: props.labels.costPage.costInfo.mainCategory,
            colId: "mainCategory",
            valueGetter: function (params) {
                return params.data.position.mainCostTypeDescription;
            }
        },
        {
            headerName: props.labels.costPage.costInfo.category,
            colId: "category",
            valueGetter: function (params) {
                return params.data.position.costTypeDescription;
            }
        },
        {
            headerName: props.labels.costPage.costInfo.VIN,
            colId: "VIN",
            valueGetter: function (params) {
                return params.data.position.car ? params.data.position.car.vin : "";
            }
        },
        {
            headerName: props.labels.costPage.costInfo.plateNumber,
            colId: "plateNumber",
            valueGetter: function (params) {
                return params.data.position.car ? params.data.position.car.licensePlate : "";
            }
        },
        {
            headerName: props.labels.costPage.costInfo.unitPriceNoVat,
            colId: "unitPriceNoVat",
            valueGetter: function (params) {
                return params.data.position.pricePerUnitExclTax;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels.costPage.costInfo.unitPriceWithVat,
            colId: "unitPriceWithVat",
            valueGetter: function (params) {
                return params.data.position.pricePerUnitInclTax;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels.costPage.costInfo.amount,
            colId: "amount",
            valueGetter: function (params) {
                return params.data.position.quantity;
            },
            valueFormatter: function (params) {
                return thousandsSeparator(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels.costPage.costInfo.totalPriceNoVat,
            colId: "totalPriceNoVat",
            valueGetter: function (params) {
                return params.data.position.priceExclTax;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels.costPage.costInfo.VAT,
            colId: "VAT",
            valueGetter: function (params) {
                return params.data.position.taxAmount;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels.costPage.costInfo.totalPriceWithVat,
            colId: "totalPriceWithVat",
            valueGetter: function (params) {
                return params.data.position.priceInclTax;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
    ];
    // get all invoice positions in one array
    let invoicePositions = [];
    props.invoiceRawData.forEach(element => {
        if (element.invoicePositions && element.invoicePositions.length > 0) {
            element.invoicePositions.forEach(e => {
                invoicePositions.push({ invoiceNumber: element.invoiceNumber, invoiceId: element.id, position: e });
            });
        }
    });
    return (
        <div className="section-container-content">
            <h1 className={props.titleSize}>{props.title}</h1>
            <GridHandler
                data={invoicePositions}
                columnDefs={columnDefs}
                downloadable={true}
                gridName={props.carId ? `upto_incoming_invoices_${props.carId}` : `upto_incoming_invoices`}
                fileName={props.vin ? `upto_${props.title?.toLowerCase()}_${props.vin}` : `upto_${props.title.toLowerCase()}`}
                downloadTextCsv={props.labels.otherElements.downloadCsv}
                downloadTextExcel={props.labels.otherElements.downloadExcel}
            ></GridHandler>
        </div>

    )
}

export default InvoicePositionsOverview